import * as React from "react"
import { graphql, type HeadFC, type PageProps } from "gatsby"
import logo from "../images/loaf-icon.png"
import founders from "../images/founders.png"
import render from "../images/render.png"

import { CheckCircleIcon } from "@heroicons/react/24/outline";
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import { PlusIcon } from "@heroicons/react/24/outline";
import { Bars2Icon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Modal from 'react-modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import BusinessIcon from '@mui/icons-material/Business';
import HouseIcon from '@mui/icons-material/House';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import Helmet from 'react-helmet';

type DataProps = {
  site: {
    siteMetadata: {
      title: string,
      description: string
    }
  }
}

const IndexPage: React.FC<PageProps> = () => {
  
  let simpleState = {
    first_name: '',
    last_name: '',
    work_number: '',
    company_name: ''
  }

  const handleSimpleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    simpleState[key as keyof typeof simpleState] = e.target.value
  }

  let corsfixer_url = process.env.GATSBY_CORSFIXER_URL || ''
  let freshsales_url = process.env.GATSBY_FRESHSALES_URL || ''

  const simpleSubmit = async (e : React.FormEvent<HTMLFormElement>) => {
    // Prevent the default submit and page reload
    e.preventDefault()

    let id = (Math.random() + 1).toString(36).substring(2)
    let r_email = id + '@not-loaf.com';

    const response = await fetch(corsfixer_url, {  
      method: 'POST',
      body: JSON.stringify({
        call_hostname: freshsales_url,
        call_port: 443,
        call_path: '/crm/sales/api/contacts',
        call_method: 'POST',
        call_headers: {
          'Authorization': 'Token token=JroeuQB3nTE-p4trLZ9Lhw', 
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        call_payload: {
          first_name: simpleState.first_name,
          last_name: simpleState.last_name, 
          company_name: simpleState.company_name,
          work_number: simpleState.work_number,
          email: r_email
        }
      })
    })

    if (response.ok) {
      closeBelModal()
      openSnackbarSuccess()
    }
    else {
      openSnackbarFail()
    }
  }

  let advState = {
    first_name: '',
    last_name: '',
    work_number: '',
    company_name: '',
    email: '',
    zipcode: ''
  }

  const handleAdvChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    advState[key as keyof typeof advState] = e.target.value
  }

  const advSubmit = async (e : React.FormEvent<HTMLFormElement>) => {
    // Prevent the default submit and page reload
    e.preventDefault()

    const response = await fetch(corsfixer_url, {  
      method: 'POST',
      body: JSON.stringify({
        call_hostname: freshsales_url,
        call_port: 443,
        call_path: '/crm/sales/api/contacts',
        call_method: 'POST',
        call_headers: {
          'Authorization': 'Token token=JroeuQB3nTE-p4trLZ9Lhw', 
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        call_payload: {
          first_name: advState.first_name,
          last_name: advState.last_name, 
          company_name: advState.company_name,
          work_number: advState.work_number,
          email: advState.email,
          zipcode: advState.zipcode,
        }
      })
    })

    if (response.ok) {
      closeModal()
      openSnackbarSuccess()
    }
    else {
      openSnackbarFail()
    }
  }
  interface ISnackbar {
    open: boolean,
    type: AlertColor
  }
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [belOpen, setBelOpen] = React.useState(false);
  const [voorwaardenOpen, setVoorwaardenOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState<ISnackbar>({open: false, type: 'success'});

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  function openModal() {
    setIsOpen(true);
    document.body.style.overflow = 'hidden';
  }

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflow = 'unset';
  }

  function openBelModal() {
    setBelOpen(true);
    document.body.style.overflow = 'hidden';
  }

  function closeBelModal() {
    setBelOpen(false);
    document.body.style.overflow = 'unset';
  }

  function openVoorwaardenModal() {
    setVoorwaardenOpen(true);
    document.body.style.overflow = 'hidden';
  }

  function closeVoorwaardenModal() {
    setVoorwaardenOpen(false);
    document.body.style.overflow = 'unset';
  }

  function openSnackbarSuccess() {
    setSnackbarOpen({open: true, type: 'success'});
  }

  function openSnackbarFail() {
    setSnackbarOpen({open: true, type: 'error'});
  }

  function closeSnackbar() {
    setSnackbarOpen({open: false, type: 'success'});
  }

  const bg = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, .6)',
      display: "flex"
    }
  };

  return (
    <main className="h-screen">
        <Helmet>
        <title>Loaf IT - Bakkerij software</title>
        <meta
        name="description"
          content='Broodnodige innovatie voor de ambachtelijke bakkerij! Loaf IT, jouw software partner van web tot winkel. Loaf IT bied software voor de bakkerij en bakkers in het kader van kassa systemen, webshops, drive-thru, brood bezorging. Beheer van recepturen en productielijsten zoals baklijsten en banket werkstaten word ook aangeboden.'
        />
        <meta name="google-site-verification" content="rCllVyMUVwop59NNRMTlAN8uoLS1hJ6m2hg0JZXCxMA" />
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <Snackbar open={snackbarOpen.open} autoHideDuration={6000} onClose={closeSnackbar} anchorOrigin={{vertical: 'bottom', horizontal:'center'}}>
        <Alert onClose={closeSnackbar} severity={snackbarOpen.type} sx={{ width: '100%' }}>
        {snackbarOpen.type == 'success' ?  <p>Bedankt voor je interesse, we nemen zo spoedig mogelijk contact met je op!</p> : <p>Er is iets misgegaan, controleer je gegevens of probeer het later nog een keer.</p> }
         
        </Alert>
      </Snackbar>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="sign up modal"
        className="w-[95%] xl:w-auto m-auto"
        style={bg}
      >
        <div className="w-full xl:w-[512px] xl:h-[736px] bg-white rounded-xl flex flex-col p-8 m-auto">
        <XMarkIcon onClick={() => closeModal()} className="h-6 w-6 self-end cursor-pointer"/>
        <h1 className="text-2xl text-center my-2">Vul je gegevens in en claim jouw plek als co-creatie partner</h1>
        <form onSubmit={(e) => advSubmit(e)} className="w-full xl:p-8 ">
          <div className="w-full flex flex-row justify-between">
            <div className="w-[48%] flex flex-col">
              <label className="text-basis" htmlFor="form-first-name">Voornaam</label>
              <input onChange={(event)=>handleAdvChange(event)} className="bg-gray-200 rounded-md p-2" name="first_name" id="form-first-name" type="text" placeholder="Voornaam" required/>
            </div>
            <div className="w-[48%] flex flex-col">
              <label className="text-basis" htmlFor="form-last-name">Achternaam</label>
              <input onChange={(event)=>handleAdvChange(event)} className="bg-gray-200 rounded-md p-2" name="last_name" id="form-last-name" type="text" placeholder="Achternaam" required/>
            </div>
          </div>
          <div className="w-full flex flex-col my-2">
            <label className="text-basis" htmlFor="form-email">E-mailadres</label>
            <input onChange={(event)=>handleAdvChange(event)} className="bg-gray-200 rounded-md p-2" name="email" id="form-email" type="email" placeholder="E-mailadres" required/>
          </div>
          <div className="w-full flex flex-col my-2">
            <label className="text-basis" htmlFor="form-phone-number">Telefoonnummer</label>
            <input onChange={(event)=>handleAdvChange(event)} className="bg-gray-200 rounded-md p-2" name="work_number" id="form-phone-number" type="text" placeholder="Telefoonnummer" required/>
          </div>
          <div className="w-full flex flex-col my-2">
            <label className="text-basis" htmlFor="form-company">Bedrijfsnaam</label>
            <input onChange={(event)=>handleAdvChange(event)} className="bg-gray-200 rounded-md p-2" name="company_name" id="form-company" type="text" placeholder="Bedrijfsnaam" required/>
          </div>
          <div className="w-full flex flex-col my-2">
            <label className="text-basis" htmlFor="form-postal-code">Postcode bezoekvestiging</label>
            <input onChange={(event)=>handleAdvChange(event)} className="bg-gray-200 rounded-md p-2" name="zipcode" id="form-postal-code" type="text" placeholder="Postcode" required/>
          </div>
          <div className="w-full flex my-4 items-center">
            <input className="bg-gray-200 rounded-md w-4 h-4" id="form-agree" type="checkbox" required/>
            <label className="text-xs ml-4" htmlFor="form-agree">Ik ga akkoord met het privacybeleid en de voorwaarden voor de aanmelding als co-creatie partner van Loaf IT</label>
          </div>
          <div className="w-full flex my-8 justify-center">
            <input className="bg-[#FFA329] cursor-pointer text-white rounded-full w-64 h-10" id="form-submit" type="submit" value="Claim je gratis plek!"/>
          </div>
        </form>
        </div>
      </Modal>

      <Modal
        isOpen={belOpen}
        onRequestClose={closeBelModal}
        contentLabel="bel mij modal"
        className="w-[95%] xl:w-auto m-auto"
        style={bg}
      >
        <div className="w-full xl:w-[400px] xl:h-[512px] bg-white rounded-xl flex flex-col p-8 m-auto">
        <XMarkIcon onClick={() => closeBelModal()} className="h-6 w-6 self-end cursor-pointer"/>
        <h1 className="text-2xl text-center my-2">Op welk nummer kunnen we je bereiken?</h1>
        <form onSubmit={(e) => simpleSubmit(e)} className="w-full xl:p-8 ">
          <div className="w-full flex flex-row justify-between">
            <div className="w-[48%] flex flex-col">
              <label className="text-basis" htmlFor="form-first-name">Voornaam</label>
              <input onChange={(event)=>handleSimpleChange(event)} className="bg-gray-200 rounded-md p-2" name="first_name" type="text" placeholder="Voornaam" required/>
            </div>
            <div className="w-[48%] flex flex-col">
              <label className="text-basis" htmlFor="form-last-name">Achternaam</label>
              <input onChange={(event)=>handleSimpleChange(event)} className="bg-gray-200 rounded-md p-2" name="last_name" type="text" placeholder="Achternaam" required/>
            </div>
          </div>
          <div className="w-full flex flex-col my-2">
            <label className="text-basis" htmlFor="form-company">Bedrijfsnaam</label>
            <input onChange={(event)=>handleSimpleChange(event)} className="bg-gray-200 rounded-md p-2" name="company_name" type="text" placeholder="Bedrijfsnaam" required/>
          </div>
          <div className="w-full flex flex-col my-2">
            <label className="text-basis" htmlFor="form-phone-number">Telefoonnummer</label>
            <input onChange={(event)=>handleSimpleChange(event)} className="bg-gray-200 rounded-md p-2" name="work_number" type="text" placeholder="Telefoonnummer" required/>
          </div>
          <div className="w-full flex my-4 items-center">
            <input className="bg-gray-200 rounded-md w-4 h-4" id="form-agree" type="checkbox" required/>
            <label className="text-xs ml-4" htmlFor="form-agree">Ik ga akkoord met het privacybeleid van Loaf IT </label>
          </div>
          <div className="w-full flex my-8 justify-center">
            <input className="bg-[#FFA329] cursor-pointer text-white rounded-full w-1/2 h-10" id="form-submit" type="submit" value="Bel mij!"/>
          </div>
        </form>
        </div>
      </Modal>

      <Modal
        isOpen={voorwaardenOpen}
        onRequestClose={closeVoorwaardenModal}
        contentLabel="Voorwaarden en Privacystatement"
        className="w-[95%] h-[75%] xl:w-auto m-auto"
        style={bg}
      >
        <div className="w-[95%] h-full bg-white rounded-xl flex flex-col p-8 m-auto overflow-y-auto">
        <XMarkIcon onClick={() => closeVoorwaardenModal()} className="h-6 w-6 self-end cursor-pointer"/>
        <h1 className="text-2xl my-2">Voorwaarden</h1>
        <p>Beste potentiële partner,<br/><br/>

          Samenwerken aan de ontwikkeling van ons software-platform voor de bakkerijbranche is een spannende en veelbelovende onderneming. 
          We willen graag een overzicht van de voorwaarden met je delen, die de basis vormen voor ons samenwerkingsverband. Maar voordat we dat doen, willen we je enthousiasmeren om met ons in co-creatie samen te werken aan dit project.<br/><br/>

          Ons gezamenlijke doel is om een innovatief software-platform te ontwikkelen dat specifiek is afgestemd op de behoeften van de bakkerijbranche. We geloven dat een nauwe samenwerking tussen onze bedrijven de sleutel is tot succes. Jullie input, expertise en feedback zijn van onschatbare waarde bij het vaststellen van de behoeften en het bepalen van de functionaliteiten die relevant zijn voor de bakkerijbranche. Samen kunnen we de efficiëntie, productiviteit en winstgevendheid van bakkerijen verbeteren door middel van technologische oplossingen.<br/>
        </p>
        <h2 className="text-lg my-2">Wat wij van jou verwachten:</h2>
        <p>
        Actieve betrokkenheid:<br/>
        <ul className="list-disc list-outside xl:w-[75%] ml-6">
          <li>Periodieke deelname aan overlegmomenten om verbeteringen te bespreken, inclusief interviews om jullie wensen en behoeften in kaart te brengen (o.a. 2 bezoeken op jullie locatie in de eerste maand).</li>
          <li>Ten minste één vertegenwoordiger aanwezig op elk overlegmoment.</li>
          <li>Consistente en actieve betrokkenheid bij het ontwikkelingsproces door waardevolle input en feedback te leveren tijdens overlegmomenten en bij oplevering van nieuwe functionaliteiten.</li>
        </ul> <br/>
        
        Tijdige communicatie: <br/>
        <ul className="list-disc list-outside xl:w-[75%] ml-6">
          <li>Tijdige reactie (binnen 1 week) op verzoeken, vragen en feedback via e-mail of telefonisch.</li>
          <li>Open en transparante communicatie om uitdagingen snel op te lossen en de ontwikkeling soepel te laten verlopen.</li>
        </ul> <br/>
        Testen en feedback: <br/>
        <ul className="list-disc list-outside xl:w-[75%] ml-6">
          <li>Actieve deelname aan het testen van het software-platform, bij voorkeur in de praktijk, en het geven van gedetailleerde feedback.</li>
          <li>Delen van ervaringen en inzichten om 'kinderziektes' en verbeterpunten te identificeren.</li>
          <li>Vrijmaken van tijd en middelen voor tests en waardevolle feedback.</li>
        </ul> <br/>
        </p>

        <h2 className="text-lg my-2">Wat jij van ons kan verwachten:</h2>
        <p>
        Feedbackverwerking en verbeteringen: <br/>
        <ul className="list-disc list-outside xl:w-[75%] ml-6">
          <li>Actieve deelname aan het testen van het software-platform, bij voorkeur in de praktijk, en het geven van gedetailleerde feedback.</li>
          <li>Delen van ervaringen en inzichten om 'kinderziektes' en verbeterpunten te identificeren.</li>
          <li>Vrijmaken van tijd en middelen voor tests en waardevolle feedback.</li>
        </ul> <br/>

        <ul className="list-disc list-outside xl:w-[75%] ml-6">
          <li>Bevindingen uit overlegmomenten leggen wij vast en gebruiken wij als basis om te onderzoeken wat de oplossing is voor jullie uitdagingen.</li>
          <li>Voortdurende ontwikkeling van modules op basis van nieuwe inzichten.</li>
          <li>Bij het ontwerpen van nieuwe modules zitten we naast jullie aan tafel en luisteren we naar jullie uitdagingen, wensen en feedback.</li>
          <li>Actief luisteren naar jullie input en toepassen van feedback om het platform te verbeteren.</li>
        </ul> <br/>

        Toegewijde ondersteuning: <br/>
        <ul className="list-disc list-outside xl:w-[75%] ml-6">
          <li>Wij helpen bij de voorbereiding van het in gebruik nemen van modules.</li>
          <li>Wij bieden ondersteuning en training bij het gebruik van het software-platform.</li>
        </ul> <br/>
        
        Regelmatige updates en transparantie: <br/>
        <ul className="list-disc list-outside xl:w-[75%] ml-6">
          <li>Maandelijkse updates over de voortgang van het project en belangrijke mijlpalen.</li>
          <li>Duidelijke en begrijpelijke updates over ontwikkelingen en uitdagingen.</li>
          <li>Open en transparante samenwerking waarin jullie op de hoogte zijn van de stand van zaken.</li>
        </ul> <br/>

        Hoogwaardige software oplossing: <br/>
        <ul className="list-disc list-outside xl:w-[75%] ml-6">
          <li>Hoogwaardige technische oplossingen die voldoen aan de behoeften van de bakkerijbranche.</li>
          <li>Gebruiksvriendelijke software die jouw werk makkelijker maakt.</li>
        </ul> <br/>

        We willen onze samenwerkingspartners een extra stimulans bieden. Zij kunnen het software-platform kosteloos gebruiken gedurende de samenwerkingsperiode en hebben directe invloed op de ontwikkeling en functionaliteiten ervan. We geloven dat dit onze samenwerking nog aantrekkelijker maakt en een win-winsituatie creëert.<br/> <br/>

        Als je geïnteresseerd bent in deze samenwerking en wilt bespreken hoe we verder kunnen gaan, aarzel dan niet om contact met ons op te nemen. We kijken ernaar uit om met jullie in co-creatie samen te werken en iets geweldigs te ontwikkelen voor de bakkerijbranche! <br/> <br/>
        </p>
        <h1 className="text-2xl my-2">Privacybeleid</h1>
        <p className="font-bold">Loaf IT, gevestigd aan Nieuwe Schaft 25 3991 AS Houten, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.<br/><br/></p>
        <h2 className="text-lg my-2">Contactgegevens:</h2>
        <p>https://loaf-it.com<br/>Nieuwe Schaft 25<br/>3991 AS Houten<br/>+31648458614</p>
        <h2 className="text-lg my-2">Persoonsgegevens die wij verwerken:</h2>
        <p>
          Loaf IT verwerkt je persoonsgegevens doordat je gebruikt maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt.<br/><br/>
          Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:<br/>
          <ul className="list-disc list-outside xl:w-[75%] ml-6">
          <li>Voor- en achternaam</li>
          <li>Adresgegevens</li>
          <li>Telefoonnummer</li>
          <li>E-mailadres</li>
          <li>Overige gegevens die je actief verstrekt bijvoorbeeld door een profiel op onze website aan te maken, in correspondentie en telefonisch</li>
          <li>Lijst van contactgegevens van de klant via een app</li>
        </ul> <br/>
        Bijzondere en/of gevoelige persoonsgegevens die wij verwerken: <br/>
        Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via avg@loaf-it.com, dan verwijderen wij deze informatie. <br/><br/>

        Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken: <br/>
        Loaf IT verwerkt jouw persoonsgegevens voor de volgende doelen: <br/>
        <ul className="list-disc list-outside xl:w-[75%] ml-6">
          <li>Het afhandelen van jouw betaling</li>
          <li>Verzenden van onze nieuwsbrief en/of reclamefolder</li>
          <li>Je te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>
          <li>Je te informeren over wijzigingen van onze diensten en producten</li>
          <li>Je de mogelijkheid te bieden een account aan te maken</li>
          <li>Om goederen en diensten bij je af te leveren</li>
        </ul> <br/>
        Hoe lang we persoonsgegevens bewaren: <br/>
        Loaf IT bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens:<br/>
        <ul className="list-disc list-outside xl:w-[75%] ml-6">
          <li> Accountgegevens: tot 2 jaar na opzegging van het account.</li>
          <li>Persoonsgegevens van actieve gebruikers: de gehele periode waarin het account actief is.</li>
          <li>Persoonsgegevens van niet-actieve gebruikers: tot 2 jaar na opzegging van het account.</li>
        </ul> <br/>
        Delen van persoonsgegevens met derden: <br/>
        Loaf IT verstrekt uitsluitend aan derden en alleen als dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting.<br/><br/>

        Cookies, of vergelijkbare technieken, die wij gebruiken: <br/>
        Loaf IT gebruikt geen cookies of vergelijkbare technieken. <br/><br/>

        Gegevens inzien, aanpassen of verwijderen: <br/>
        Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Loaf IT en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen. <br/> <br/>
        Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar avg@loaf-it.com.<br/> <br/>
        Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek.<br/> <br/>
        Loaf IT wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons<br/> <br/>

        Hoe wij persoonsgegevens beveiligen: <br/>
        Loaf IT neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via avg@loaf-it.com<br/><br/>
        </p>
        <p className="font-bold">Jeroen, Jelte en Sven <br/> Loaf IT</p>
        </div>
      </Modal>

      <nav className="bg-white flex shadow-xl fixed w-full  top-0 left-0 h-20">
        <img className="py-[10px] xl:px-[64px]" src={logo} alt="loaf IT logo" />
        <div className="flex-1 flex items-center justify-end">
          <a className="hidden cursor-pointer xl:flex" onClick={() => openVoorwaardenModal()}>Voorwaarden & Privacy</a>
          <button onClick={() => openBelModal()} className=" hidden xl:flex items-center px-[24px] ml-[24px] border border-[#FFA329] text-[#FFA329] rounded-full h-3/6">Bel mij</button>
          <button onClick={() => openModal()} className="px-[24px] mx-[10px] xl:mx-[24px] bg-[#FFA329] rounded-full text-white h-3/6">Claim gratis plek</button>
        </div>
      </nav>

      <section className="xl:min-h-4/6 bg-[#FEF5D9] pt-20 flex flex-col-reverse xl:flex-row items-center">
        <div className="basis-5/12 p-8 xl:p-12 flex flex-col">
          <h1 className="text-3xl">Zullen wij jouw bakkerij helpen aan een winstgevende online omgeving?</h1>
          <p className="text-base my-10">Wil jij innoveren door nieuwe verkoopkanalen in te zetten? Wil jij af van de kopzorgen over hoe jij deze kanalen op moet zetten, hoe dit in jouw huidige proces past en hoe jij dit allemaal onder controle houdt?</p>
          <div className="xl:h-6 my-4 flex flex-row ">
            <CheckCircleIcon className="basis-1/8 h-6 mr-2" />
            <p className="basis-5/6">Nieuwe verkoopkanalen, zoals drive-thru’s en broodabonnementen</p>
          </div>
          <div className="xl:h-6 my-4 flex flex-row content-start">
            <CheckCircleIcon className="basis-1/8 h-6 mr-2" />
            <p className="basis-5/6">Software die wij samen ontwikkelen op basis van jouw input</p>
          </div>
          <div className="xl:h-6 my-4 flex flex-row ">
            <CheckCircleIcon className="basis-1/8 h-6 mr-2" />
            <p className="basis-5/6">Als bedankje gebruik jij onze software gratis</p>
          </div>
          <div className="w-64 flex flex-col">
            <button onClick={() => openModal()} className="mt-10 bg-[#FFA329] rounded-full text-white h-10 w-full self-center">Claim gratis plek</button>
            <p className="mt-2 text-xs self-center">Maar 5 plekken beschikbaar!</p>
          </div>
          
        </div>

        <div className="basis-7/12 p-8 xl:p-12 flex items-center justify-center">
          <img className="xl:w-[50%]" src={render} alt="Voorbeeld applicatie loaf it" />
        </div>
      </section>

      <section className="xl:min-h-2/6 p-8 xl:p-10 bg-white flex flex-col xl:flex-row justify-between">
        <div className="basis-[30%] flex flex-col justify-center my-4">
          <div className="flex">
            <div className="h-10 w-10 p-1 mr-2 bg-[#A329FF]/[.2] rounded-full flex items-center justify-center">
              <DirectionsCarOutlinedIcon className="fill-[#A329FF] stroke-0" />
            </div>
           
            <h1 className="mb-2 text-2xl">Drive-Thru applicatie</h1>
          </div>
          <p className="pl-12">Een drive-thru evenement maakt het mogelijk om hoger volume in korte tijd te verwerken, bijvoorbeeld voor jouw oranje tompoucen op Koningsdag of oliebollen op Oudjaarsdag.</p>
        </div>
        <div className="basis-[30%] flex flex-col justify-center my-4">
          <div className="flex">
            <div className="h-10 w-10 p-1 mr-2 bg-[#A329FF]/[.2] rounded-full flex items-center justify-center">
              <EventRepeatOutlinedIcon className="fill-[#A329FF] stroke-0" />
            </div>
            
            <h1 className="mb-2 text-2xl">Broodabonnementen</h1>
          </div>
          <p className="pl-12">Het aanbieden van jouw producten als abonnement zorgt voor terugkerende inkomsten en klantenbinding. Met onze bezorgapplicatie verplaats jij dit verkoopkanaal zelfs naar de deur van nieuwe klanten.</p>
        </div>
        <div className="basis-[30%] flex flex-col justify-center my-4">
          <div className="flex">
            <div className="h-10 w-10 p-1 mr-2 bg-[#A329FF]/[.2] rounded-full flex items-center justify-center">
              <ShoppingCartOutlinedIcon className="fill-[#A329FF] stroke-0" />
            </div>
    
            <h1 className="mb-2 text-2xl">Reguliere webshops</h1>
          </div>
          <p className="pl-12">Onze reguliere webshop oplossingen maken het mogelijk om verkopen aan consumenten en zakelijke klanten makkelijk te verwerken en integreren met jouw productieplanning. Dit zoals jij het gewend bent.</p>
        </div>
      </section>

      <section className="xl:min-h-3/6 bg-[#FEF5D9] p-8 xl:p-16 flex flex-col xl:flex-row items-center">
        <div className="xl:self-start basis-5/12 2xl:basis-4/12">
          <img src={founders} alt="Oprichters van Loaf IT" />
          <p className="text-xs mt-4">Jelte van den Akker, Jeroen Groot en Sven Hogenboom (v.l.n.r.)</p>
        </div>
        <div className="basis-7/12 2xl:basis-8/12 justify-start mt-4">
          <h1 className="text-2xl mb-10">
            Softwareliefhebbers & Bakkersfamilie
          </h1>
          <p  className="text-basis mb-10">
            Bij Loaf IT geloven we sterk in authenticiteit en innovatie. We stellen onze klanten centraal bij het bepalen van onze
            prioriteiten voor de ontwikkeling van onze software. Door nauw met onze klanten samen te werken en te luisteren naar
            hun specifieke behoeften, zorgen we ervoor dat onze oplossingen perfect aansluiten op hun bedrijfsprocessen.
          </p>
          <p className="text-basis mb-10">
            Met zijn jarenlange ervaring als software architect en data engineer, weet Jeroen hoe hij robuuste systemen kan
            ontwerpen die voldoen aan de hoogste standaarden van deze tijd. De ervaring van Jelte als software ontwikkelaar stelt
            hem in staat om praktijksituaties te vertalen naar effectieve software oplossingen. Zijn zus is banketbakster en
            inspireerde hem om zijn kennis in te zetten in de bakkerij. Sven heeft een diepe verbondenheid met de bakkerijbranche.
            Als lid van een bakkersfamilie begrijpt hij de uitdagingen en behoeften van bakkerijen als geen ander en zet hij zijn
            expertise als bedrijfsanalist en data analist in om op maat gemaakte oplossingen te bieden voor de branche.
          </p>
          <p  className="text-basis">
              Bij Loaf IT streven we ernaar om voortdurend op de hoogte te blijven van de nieuwste innovaties in de
              softwareontwikkeling en implementeren de nieuwste standaarden in ons werk. Hierdoor kunnen we onze klanten laten
              profiteren van geheel nieuwe verkoopkanalen, evenals een volledig geïntegreerde oplossing die hun bedrijfsvoering
              optimaliseert.
          </p>
        </div>
      </section>

      <section className="xl:min-h-2/6 bg-white flex flex-col items-center justify-center p-8 xl:p-16">
      <h1 className="text-2xl basis-1/4 text-center">De winstgevende formule voor jouw online omgeving:</h1>
      <div className="basis-3/4 flex flex-col xl:flex-row items-center justify-between w-full">
        <div className="basis-1/4 flex flex-col items-center my-4">
          <div className="h-10 w-10 p-1 xl:mr-4 bg-[#A329FF]/[.2] stroke-[#A329FF] rounded-full flex items-center justify-center">
            <DevicesOutlinedIcon className="fill-[#A329FF] stroke-0" />
          </div>
          <h1 className="my-2 text-2xl">Nieuwe verkoopkanalen</h1>
          <p className="text-basis text-center">
            Jouw brood in abonnementsvorm aanbieden, thuisbezorgen, drive-thru evenementen
            organiseren en webshops als online filiaal van jouw bakkerij. Alles om nieuwe klanten te binden.
          </p>
        </div>
        <PlusIcon className="h-8 w-8 mx-2 my-4" />
        <div className="basis-1/4 flex flex-col items-center my-4">
          <div className="h-10 w-10 p-1 xl:mr-4 bg-[#A329FF]/[.2] stroke-[#A329FF] rounded-full flex items-center justify-center">
            <EqualizerOutlinedIcon className="fill-[#A329FF] stroke-0" />
          </div>
          <h1 className="my-2 text-2xl">Inzicht in jouw operatie</h1>
          <p className="text-basis text-center">
            Makkelijk beheren en inzichtelijk maken van artikelen, recepturen, de productieplanning,
            verdeellijsten, wettelijke naamgevingen en nog veel meer.
          </p>
        </div>
        <Bars2Icon className="h-8 w-8 mx-2 my-4" />
        <div className="basis-1/4 flex flex-col items-center my-4">
          <div className="h-10 w-10 p-1 xl:mr-4 bg-[#A329FF]/[.2] stroke-[#A329FF] rounded-full flex items-center justify-center">
            <EuroSymbolOutlinedIcon className="fill-[#A329FF] stroke-0" />
          </div>
          <h1 className="my-2 text-2xl">Totaaloplossing</h1>
          <p className="text-basis text-center">
            Door integratie van nieuwe verkoopkanalen en de inzichten van jouw operatie wordt het mogelijk om
            van bestelling tot levering het proces te beheren, te organiseren en te optimaliseren. Hierdoor houd
            jij meer winst over.
          </p>
        </div>
      </div>
      </section>

      <section className="xl:min-h-2/6 bg-[#FEF5D9] flex flex-col p-8 xl:p-16 items-center text-center">
        <h1 className="text-2xl mb-10">Moeiteloos én kosteloos gebruik maken van onze software?</h1>
        <h2 className="text-xl">Meld je dan nu aan als co-creatie partner van ons product</h2>
        <div> 
          <a className="xl:hidden cursor-pointer m-2" onClick={() => openVoorwaardenModal()}>Voorwaarden & Privacy</a>
          <button onClick={() => openBelModal()} className="xl:hidden my-2 items-center px-[24px] xl:ml-[24px] border border-[#FFA329] text-[#FFA329] rounded-full h-5/6">Bel mij</button>
        </div>
        <button onClick={() => openModal()} className="mt-4 bg-[#FFA329] rounded-full text-white h-10 w-64">Claim gratis plek</button>
        <p className="mt-2 text-xs">Maar 5 plekken beschikbaar!</p>

      </section>
      <section className="xl:min-h-2/6 bg-[#f6f8fc] flex flex-col items-center justify-center p-8 xl:p-16">
      <div className="flex flex-col">
      <h1 className="text-2xl mb-2 ml-2">Contactgegevens</h1>
          <div className="flex ">
            <div className="flex flex-col mx-2 space-y-1">
              <BusinessIcon/>
              <HouseIcon/>
              <LocationOnIcon/>
              <EmailIcon/>
              <WorkIcon/>
            </div>
            <div className="flex flex-col items-start space-y-1">
              <p>Loaf IT</p>
              <p>Nieuwe Schaft 25</p>
              <p>3991 AS Houten</p>
              <p>info@loaf-it.com</p>
              <p>KVK-nummer: 90430018</p>
            </div>        
          </div>
        </div>
      </section>
    </main>
  )
}

export default IndexPage


export const query = graphql`
  {
    site {
      siteMetadata {
        title,
        description
      }
    }
  }
`

// export const Head: HeadFC<DataProps> = props => {
//   return <>
//     <title>Loaf IT - Bakkerij software</title>
//     <meta
//       name="description"
//       content='Broodnodige innovatie voor de ambachtelijke bakkerij! Loaf IT, jouw software partner van web tot winkel. Loaf IT bied software voor de bakkerij en bakkers in het kader van kassa systemen, webshops, drive-thru, brood bezorging. Beheer van recepturen en productielijsten zoals baklijsten en banket werkstaten word ook aangeboden.'
//     />
//     <meta name="google-site-verification" content="rCllVyMUVwop59NNRMTlAN8uoLS1hJ6m2hg0JZXCxMA" />
//   </>
// }